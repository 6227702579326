// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aws-btn {
  --slider-height-percentage: 40% !important;
  --slider-transition-duration: 575ms !important;
  --organic-arrow-thickness: 4px !important;
  --organic-arrow-border-radius: 0px !important;
  --organic-arrow-height: 40px !important;
  --organic-arrow-color: #6a6a6a !important;
  --control-button-width: 10% !important;
  --control-button-height: 25% !important;
  --control-button-background: transparent !important;
  --control-bullet-color: #6a6a6a !important;
  --control-bullet-active-color: #6a6a6a !important;
  --loader-bar-color: #851515 !important;
  --loader-bar-height: 6px !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #192534;
  color: white;
  text-align: center;
}

.productImage {
  float: left;
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.carousel {
  padding: 10px;
}

.linebreak {
  white-space: pre-wrap; /* or pre-line */
}
`, "",{"version":3,"sources":["webpack://./lib/web/index.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,8CAA8C;EAC9C,yCAAyC;EACzC,6CAA6C;EAC7C,uCAAuC;EACvC,yCAAyC;EACzC,sCAAsC;EACtC,uCAAuC;EACvC,mDAAmD;EACnD,0CAA0C;EAC1C,iDAAiD;EACjD,sCAAsC;EACtC,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,OAAO;EACP,SAAS;EACT,WAAW;EACX,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB,EAAE,gBAAgB;AACzC","sourcesContent":[".aws-btn {\n  --slider-height-percentage: 40% !important;\n  --slider-transition-duration: 575ms !important;\n  --organic-arrow-thickness: 4px !important;\n  --organic-arrow-border-radius: 0px !important;\n  --organic-arrow-height: 40px !important;\n  --organic-arrow-color: #6a6a6a !important;\n  --control-button-width: 10% !important;\n  --control-button-height: 25% !important;\n  --control-button-background: transparent !important;\n  --control-bullet-color: #6a6a6a !important;\n  --control-bullet-active-color: #6a6a6a !important;\n  --loader-bar-color: #851515 !important;\n  --loader-bar-height: 6px !important;\n}\n\n.footer {\n  position: fixed;\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  background-color: #192534;\n  color: white;\n  text-align: center;\n}\n\n.productImage {\n  float: left;\n  width: 100%;\n  height: 350px;\n  object-fit: cover;\n}\n\n.carousel {\n  padding: 10px;\n}\n\n.linebreak {\n  white-space: pre-wrap; /* or pre-line */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
